import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="navOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper1">
                <div id="block-menu-primary-links" className="block block-menu">
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first active-trail home">
                        <a href="/home" title="Home" className="active">
                          <img src="/sites/migraleve.co.uk/files/images/home.png" />
                        </a>
                      </li>
                      <li className="expanded aboutmigraines">
                        <a href="/about-migraine" title="About Migraines" className="about-migraine">
                          ABOUT MIGRAINES
                        </a>
                        <ul className="menu">
                          <li className="leaf first migrainesymptoms">
                            <a href="/migraine-symptoms" title="Migraine Types & Symptoms">
                              Migraine Types & Symptoms
                            </a>
                          </li>
                          <li className="leaf potentialmigrainecauses">
                            <a
                              href="/migraine-causes"
                              title="Potential Migraine Causes"
                            >
                              Potential Migraine Causes
                            </a>
                          </li>
                          <li className="leaf last whatcanyoudoaboutit">
                            <a
                              href="/migraine-treatment-and-prevention"
                              title="Treatment and Relief"
                            >
                              Treatment and Relief
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf ourproducts">
                        <a
                          href="/migraine-relief-products"
                          title="Our Products"
                        >
                          OUR
                          <br /> PRODUCTS
                        </a>
                      </li>
                      <li className="leaf migrainemanagementtips">
                        <a
                          href="/migraine-tips"
                          title="Migraine Management Tips"
                        >
                          MIGRAINE MANAGEMENT TIPS
                        </a>
                      </li>
                      <li className="leaf migrainetriggerdiary">
                        <a
                          href="/migraine-trigger-diary"
                          title="Migraine Trigger Diary"
                        >
                          MIGRAINE TRIGGER DIARY
                        </a>
                      </li>
                      <li className="leaf last wheretobuy">
                        <a href="/where-to-buy" title="Where to buy">
                          WHERE TO BUY
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contentOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="homeBannerWrapper">
                  <div className="homeBanner">
                    <div id="node-1" className="node">
                      <div className="content clear-block">
                        <div className="bannerImage">
                          <img
                            src="/sites/migraleve.co.uk/files/images/Migraleve_Group2.jpg"
                            alt
                          />
                        </div>
                        <div className="bannerData">
                          <h1>
                            There are around 10 million migraine sufferers in the UK.
                          </h1>
                          <p>
                            Approximately{" "}
                            <strong>1 in 5 women and 1 in 15 men</strong> will develop a migraine at some time
                            in their life, so it’s really important that there’s a migraine treatment you can trust.
                          </p>
                        </div>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="homePromoWrapper">
                    <div id="block-block-1" className="block block-block">
                      <div className="content">
                        <ul>
                          <li className="promoMargin">
                            <div className="textWrapper tips">
                              <h4>Tips</h4>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Find out more" href="/tips">
                                find out more
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="textWrapper about_migraine">
                              <h4>About migraines</h4>
                              <p>
                                What is a migraine? What causes it? What can you
                                do about it?
                              </p>
                              <a title="Get the Answers" href="/migraine-symptoms">
                                Get the Answers
                              </a>
                            </div>
                          </li>
                          <li className="promoMargin">
                            <div className="textWrapper buying_migraleve">
                              <h4>
                                Buying Migraleve<em>™</em>
                              </h4>
                              <p>
                                Find your nearest stockist of Migraleve
                                <em>™</em> products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </li>
                          <li className="last">
                            <div className="textWrapper triggers_diary">
                              <h4>Triggers Diary</h4>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                title=" Try a trigger diary"
                                href="/trigger-diary"
                              >
                                {" "}
                                Try a trigger diary
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="product-section">
                  <a title="Products" href="/products">
                    <div className="product-heading">
                      <h2 className="text-bold">Discover our products</h2>
                      <h3 className="text-normal">
                        Which MIGRALEVE™ product is the one for you?{" "}
                      </h3>
                    </div>
                    <div className="row">
                      <div className="col product-item">
                        <img src="/sites/migraleve.co.uk/files/images/MIG_COMP_24s_3D_T.jpg" />
                        <h3 className="text-bold">MIGRALEVE™</h3>
                      </div>
                      <div className="col product-item">
                        <img src="/sites/migraleve.co.uk/files/images/MIG_PINK_24s_3D_T.jpg" />
                        <h3 className="text-bold">MIGRALEVE™ PINK</h3>
                      </div>
                      <div className="col product-item">
                        <img src="/sites/migraleve.co.uk/files/images/MIG_YELLOW_24s_3D_T.jpg" />
                        <h3 className="text-bold">MIGRALEVE™ YELLOW</h3>
                      </div>
                    </div>
                    <div className="view-all-projects">
                      <h3 className="product-link">
                        <span>&gt; Find out more</span>
                      </h3>
                    </div>
                  </a>
                </div>
                <div className="footerContent">
                  <div
                    id="block-menu-menu-site-footer-menu"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="leaf first privacypolicy">
                          <a href="/privacy-policy" title="Privacy policy">
                            Privacy policy
                          </a>
                        </li>
                        <li className="leaf legalnotice">
                          <a href="/legal-notice" title="Legal notice">
                            Legal notice
                          </a>
                        </li>
                        <li className="leaf contactus">
                          <a href="/contact-us" title="Contact us">
                            Contact us
                          </a>
                        </li>
                        <li className="leaf cookiepolicy">
                          <a href="/cookie-policy" title="Cookie Policy">
                            Cookie Policy
                          </a>
                        </li>
                        <li className="leaf modernslaveryactstatement">
                          <a
                            href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                            target="_blank"
                          >
                            Modern Slavery Act Statement
                          </a>
                        </li>
                        <li className="leaf last active-trail cookiesettings">
                          <a
                            id="ot-sdk-btn"
                            className="ot-sdk-show-settings active"
                          >
                            Cookie Settings
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="block-block-3" className="block block-block">
                    <div className="content">
                      <p>
                        <strong>
                          Always read the label. Ask your pharmacist for advice.
                        </strong>
                      </p>
                      <p>
                        MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                        codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                        codeine and buclizine. MIGRALEVE<em>™</em> Yellow
                        contains paracetamol and codeine.
                      </p>
                      <p>
                        Can cause addiction. For three days use only. This
                        medicine can make you feel sleepy. Do not drive while
                        taking this medicine until you know how it makes you
                        feel.
                      </p>
                      <p>
                        This site is published by Johnson &amp; Johnson Limited
                        which is solely responsible for its contents. It is
                        intended for a UK audience. MIGRALEVE<em>™</em> is a
                        registered trademark.
                      </p>
                      <p>
                        This site is best viewed with the following web
                        browsers: Internet Explorer version 8, Firefox version
                        2+, Safari 3+, Chrome version 3+.
                      </p>
                      <p>
                        © Johnson &amp; Johnson Limited 2024. This site was last
                        updated on: 15<sup>th </sup>August 2024
                      </p>
                    </div>
                  </div>
                  <p>
                    <a
                      id="popup-trigger"
                      href="/sites/migraleve.co.uk/files/pop-up-text"
                      rel="lightmodal"
                    >
                      hide this
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
